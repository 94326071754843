<template>
  <div class="calendar">
    <div class="main-calendar d-flex">
      <div class="ranges-list">
        <ul>
          <li
            v-for="item in rangesSelection"
            :key="item.name"
            @click="handleRangeSelect(...item.value)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="left-calendar">
        <div class="controls d-flex align-center">
          <div class="prev-month">
            <feather-icon
              icon="ChevronLeftIcon"
              size="24"
              @click="handleClickPrev(2)"
            />
          </div>
          <div class="select-month-year text-center w-100">
            <h2>{{ monthsName[curMonth] }} {{ curYear }}</h2>
          </div>
          <div class="next-month">
            <feather-icon
              icon="ChevronRightIcon"
              size="24"
              @click="handleClickNext(1)"
            />
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th v-for="item in labels" :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(row, i) in leftCalendarDatas"
              :key="`row-left-${i}`"
              class="calendar-row"
            >
              <td
                v-for="(cell, j) in row"
                :key="`cell-left-${j}`"
                :data-value="!cell.isPrevMonth && !cell.isNextMonth ? `${cell.month}/${cell.date}/${cell.year}` : null"
                :data-prev="cell.isPrevMonth ? `${cell.month}/${cell.date}/${cell.year}` : null"
                :data-next="cell.isNextMonth ? `${cell.month}/${cell.date}/${cell.year}` : null"
                :data-month-year="!cell.isPrevMonth && !cell.isNextMonth ? `${cell.month}${cell.year}` : null"
                :class="{
                  'calendar-cell' : true,
                  'other-month' : cell.isPrevMonth || cell.isNextMonth
                }"
                @click="handleCellClick($event, cell)"
                @mouseover="handleMouseOver($event, `${cell.month}/${cell.date}/${cell.year}`)"
              >
                {{ cell.date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="right-calendar">
        <div class="controls d-flex align-center">
          <div class="prev-month">
            <feather-icon
              icon="ChevronLeftIcon"
              size="24"
              @click="handleClickPrev(1)"
            />
          </div>
          <div class="select-month-year text-center w-100">
            <h2>{{ curMonth + 2 > 12 ? monthsName[0] : monthsName[curMonth + 1] }} {{ curMonth + 2 > 12 ? curYear + 1 : curYear }}</h2>
          </div>
          <div class="next-month">
            <feather-icon
              icon="ChevronRightIcon"
              size="24"
              @click="handleClickNext(2)"
            />
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th v-for="item in labels" :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(row, i) in rightCalendarDatas"
              :key="`row-right-${i}`"
              class="calendar-row"
            >
              <td
                v-for="(cell, j) in row"
                :key="`cell-right-${j}`"
                :data-value="!cell.isPrevMonth && !cell.isNextMonth ? `${cell.month}/${cell.date}/${cell.year}` : null"
                :data-prev="cell.isPrevMonth ? `${cell.month}/${cell.date}/${cell.year}` : null"
                :data-next="cell.isNextMonth ? `${cell.month}/${cell.date}/${cell.year}` : null"
                :data-month-year="!cell.isPrevMonth && !cell.isNextMonth ? `${cell.month}${cell.year}` : null"
                :class="{
                  'calendar-cell' : true,
                  'other-month' : cell.isPrevMonth || cell.isNextMonth
                }"
                @click="handleCellClick($event, cell)"
                @mouseover="handleMouseOver($event, `${cell.month}/${cell.date}/${cell.year}`)"
              >
                {{ cell.date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="results.length > 0" class="results">
      <div v-for="(item, i) in results" :key="i">
        From - To:
        <InputDateComponent
          :placeholder="displayFormat"
          :format="displayFormat"
          :value="formatValue(item[0])"
          :valid="rangeValidation[i] ? rangeValidation[i][0] : true"
          class="mr-2"
          @onChange="(data) => handleRangeChange(data, i, 0)"
        />
        <InputDateComponent
          :placeholder="displayFormat"
          :format="displayFormat"
          :value="formatValue(item[1])"
          :valid="rangeValidation[i] ? rangeValidation[i][1] : true"
          @onChange="(data) => handleRangeChange(data, i, 1)"
        />
        <feather-icon
          icon="XCircleIcon"
          size="24"
          class="ml-1 cursor-pointer"
          style="margin-bottom: 4px"
          @click="handleClearResult(i)"
        />
      </div>
    </div>

    <div v-if="disabled" class="disabled" />
  </div>
</template>

<script>
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import InputDateComponent from '../InputDateComponent/InputDateComponent.vue'

import './style.scss'

export default {
  name: 'CalendarComponent',
  components: {
    InputDateComponent,
  },
  props: {
    mondayIsStartDay: {
      type: Boolean,
      default: false,
    },
    startMonth: {
      type: Number,
      default: 10,
    },
    value: {
      type: Array,
      default: () => [],
    },
    displayFormat: {
      type: String,
      default: 'MM/dd/yyyy',
    },
    limitRanges: {
      type: Number,
      default: 3,
    },
    ranges: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const baseLabels = ['Tu', 'We', 'Th', 'Fr', 'Sa']
    const labels = this.mondayIsStartDay ? ['Mo', ...baseLabels, 'Su'] : ['Su', 'Mo', ...baseLabels]

    return {
      leftCalendarDatas: [],
      rightCalendarDatas: [],
      curYear: 0,
      curDate: 0,
      curMonth: 0,
      labels,
      results: [],
      startDate: '',
      endDate: '',
      isSelecting: false,
      tempRange: [],
      currentLocalDate: {},
      rangesSelection: [],
      rangeValidation: [],
      monthsName: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Otc',
        'Nov',
        'Dec',
      ],
    }
  },
  watch: {
    results(newResults, oldResults) {
      console.log(newResults, 'newResults')
      console.log(oldResults, 'oldResults')
      if (newResults.length !== oldResults.length) {
        this.$emit('onChange', newResults)
      } else if (newResults.length > 0 && oldResults.length > 0 && newResults.length === oldResults.length) {
        let hasChange = false
        for (let i = 0; i < newResults.length; i += 1) {
          if (newResults[i][0] !== oldResults[i][0] || newResults[i][1] !== oldResults[i][1]) {
            hasChange = true
          }
          if (hasChange) {
            console.log('hasChange')
            this.$emit('onChange', newResults)
            break
          }
        }
      }
    },
    value(newValue) {
      this.results = newValue
      this.$nextTick(() => {
        this.clearAllHighlighted()
        this.highlightToday()
        this.highlightResults()
      })
    },
  },
  created() {
    const date = new Date()
    const curYear = date.getFullYear()
    const curMonth = date.getMonth()
    const curDate = date.getDate()
    const nextMonth = curMonth + 1 > 11 ? 0 : curMonth + 1
    const nextYeah = curMonth + 1 > 11 ? curYear + 1 : curYear

    this.currentLocalDate = {
      month: curMonth + 1,
      date: curDate,
      year: curYear,
      dateString: date.toLocaleDateString(),
    }
    this.curMonth = curMonth
    this.curDate = curDate
    this.curYear = curYear
    this.leftCalendarDatas = this.renderCalendar(curYear, curMonth)
    this.rightCalendarDatas = this.renderCalendar(nextYeah, nextMonth)

    this.rangesSelection = [
      {
        name: 'Today',
        value: [this.currentLocalDate.dateString, this.currentLocalDate.dateString],
      },
      {
        name: 'This Month',
        value: [
          `${curMonth + 1}/1/${curYear}`,
          `${curMonth + 1}/${this.daysInMonth(curYear, curMonth)}/${curYear}`,
        ],
      },
      {
        name: 'This Year',
        value: [
          `1/1/${curYear}`,
          `12/${this.daysInMonth(curYear, 12)}/${curYear}`,
        ],
      },
      {
        name: 'Last Month',
        value: [
          `${curMonth - 1 < 0 ? 12 : curMonth - 1}/1/${curMonth - 1 < 0 ? curYear - 1 : curYear}`,
          `${curMonth - 1 < 0 ? 12 : curMonth - 1}/${this.daysInMonth(curMonth - 1 < 0 ? curYear - 1 : curYear, curMonth - 1 < 0 ? 12 : curMonth - 1)}/${curMonth - 1 < 0 ? curYear - 1 : curYear}`,
        ],
      },
      {
        name: 'Last 3 Months',
        value: [
          `${format(sub(new Date(`${curMonth + 1}/${curDate}/${curYear}`), { months: 3 }), 'MM/dd/yyyy')}`,
          `${curMonth + 1}/${curDate}/${curYear}`,
        ],
      },
    ]

    if (this.ranges.length) this.rangesSelection = this.ranges
  },
  mounted() {
    const { value } = this
    this.highlightToday()

    if (value.length > 0) {
      value.forEach((item, i) => {
        const [from, to] = item
        const fromDate = new Date(from)
        const toDate = new Date(to)
        this.selectDateInRange(fromDate, toDate, true, i + 1)
      })
      this.results = value
    }
  },
  methods: {
    /**
     * Get how many days of current month
     */
    daysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },
    renderCalendar(year, month) {
      const maxRows = 6
      const maxCells = 7
      const totalDays = this.daysInMonth(year, month + 1)
      const firstDate = new Date(year, month).getDay()
      const lastDateOfPrevMonth = month === 0 ? new Date(year - 1, 12, 0).getDate() : new Date(year, month, 0).getDate()

      const dateData = []
      let countDay = 1
      let nextMonthDay = 1
      let preDaysLeft = firstDate - 1
      for (let i = 0; i < maxRows; i += 1) {
        dateData[i] = []
        for (let j = 0; j < maxCells; j += 1) {
          if (this.mondayIsStartDay) {
            if (i === 0 && firstDate === 0 && j < 6) {
              dateData[i][j] = {
                date: lastDateOfPrevMonth + j - 5,
                month: month - 1 < 0 ? 12 : month,
                year: month - 1 < 0 ? year - 1 : year,
                isPrevMonth: true,
              }
            } else if (i === 0 && j < firstDate - 1) {
              dateData[i][j] = {
                date: lastDateOfPrevMonth - preDaysLeft + 1,
                month: month - 1 < 0 ? 12 : month,
                year: month - 1 < 0 ? year - 1 : year,
                isPrevMonth: true,
              }
              preDaysLeft -= 1
            } else if (countDay > totalDays) {
              dateData[i][j] = {
                date: nextMonthDay,
                month: month + 1 > 11 ? 1 : month + 2,
                year: month + 1 > 11 ? year + 1 : year,
                isNextMonth: true,
              }
              nextMonthDay += 1
            } else {
              dateData[i][j] = {
                date: countDay,
                month: month + 1,
                year,
              }
              countDay += 1
            }
          } else if (i === 0 && j < firstDate) {
            dateData[i][j] = {
              date: lastDateOfPrevMonth - preDaysLeft,
              month: month - 1 < 0 ? 12 : month,
              year: month - 1 < 0 ? year - 1 : year,
              isPrevMonth: true,
            }
            preDaysLeft -= 1
          } else if (countDay > totalDays) {
            dateData[i][j] = {
              date: nextMonthDay,
              month: month + 1 > 11 ? 1 : month + 2,
              year: month + 1 > 11 ? year + 1 : year,
              isNextMonth: true,
            }
            nextMonthDay += 1
          } else {
            dateData[i][j] = {
              date: countDay,
              month: month + 1,
              year,
            }
            countDay += 1
          }
        }
      }
      return dateData
    },
    handleCellClick(e, cell) {
      const { target } = e
      const { isNextMonth, isPrevMonth } = cell
      const value = `${cell.month}/${cell.date}/${cell.year}`
      const obj = {
        from: new Date(this.startDate),
        to: new Date(value),
      }

      if (this.disabled) return

      if (this.results.length === this.limitRanges) {
        if (isNextMonth) this.handleClickNext(1)
        if (isPrevMonth) this.handleClickPrev(1)
        return
      }

      if (this.startDate && !this.endDate && (isAfter(obj.to, obj.from) || isEqual(obj.to, obj.from))) {
        if (isNextMonth) {
          this.tempRange = [this.tempRange[0], value]
          this.handleClickNext(1)
        } else {
          this.endDate = value
          this.tempRange = []
          target.classList.add('to-date')
        }
      } else {
        if (this.startDate && this.findElement('td.from-date')[0]) {
          this.findElement('td.from-date')[0].classList.remove('from-date')
        }

        this.startDate = value
        this.endDate = ''
        this.tempRange = [value]

        if (isNextMonth) {
          this.tempRange = [this.tempRange[0], value]
          this.handleClickNext(1)
          // eslint-disable-next-line no-unused-expressions
          !this.isSelecting && this.$nextTick(() => {
            const ele = this.findElement(`td[data-value="${value}"`)
            // eslint-disable-next-line no-unused-expressions
            ele[0] && ele[0].classList.add('from-date')
          })
        } else if (isPrevMonth) {
          this.startDate = value
          this.tempRange = [value]
          this.handleClickPrev(1)
        } else {
          target.classList.add('from-date')
          target.classList.remove('to-date')
        }

        this.isSelecting = true
      }

      if (this.endDate) {
        const selectedCells = this.findElement('td.in-range')
        const selectedCellsStart = this.findElement('td.from-date')[0]
        const selectedCellsEnd = this.findElement('td.to-date')[0]

        selectedCells.forEach(item => {
          item.classList.add('selected-in-range')
          item.classList.remove('in-range')
        })

        // eslint-disable-next-line no-unused-expressions
        selectedCellsStart && selectedCellsStart.classList.add('selected-from-date')
        // eslint-disable-next-line no-unused-expressions
        selectedCellsStart && selectedCellsStart.classList.remove('from-date')
        // eslint-disable-next-line no-unused-expressions
        selectedCellsEnd && selectedCellsEnd.classList.add('selected-to-date')
        // eslint-disable-next-line no-unused-expressions
        selectedCellsEnd && selectedCellsEnd.classList.remove('to-date')

        this.results = [
          ...this.results,
          [this.startDate, this.endDate],
        ]

        this.startDate = ''
        this.endDate = ''
      }

      this.highlightResults()
    },
    highlightDateInRange(from, to, selected = false, freePoint, rangeNumber) {
      // freePoint Value
      // 1 => right
      // 0 => both
      // -1 => left
      const datefrom = new Date(from)
      const dateto = new Date(to)
      const fromDate = datefrom.getDate()
      const fromMonth = datefrom.getMonth() + 1
      const fromYear = datefrom.getFullYear()
      const toDate = dateto.getDate()

      const selector = `td[data-month-year^="${fromMonth}${fromYear}"`
      this.clearAllSelectingCell(selector)

      for (let i = fromDate; i <= toDate; i += 1) {
        const ele = this.findElement(`td[data-value="${fromMonth}/${i}/${fromYear}"]`)[0]

        if (ele) {
          if (selected) {
            ele.classList.add('selected-in-range')
            if (freePoint !== undefined) {
              if (i === fromDate && freePoint === -1) {
                ele.classList.add('selected-from-date')
              }

              if (i === toDate && freePoint === 1) {
                ele.classList.add('selected-to-date')
              }
            } else {
              if (i === fromDate) {
                ele.classList.add('selected-from-date')
              }

              if (i === toDate) {
                ele.classList.add('selected-to-date')
              }
            }
          } else {
            ele.classList.add('in-range')
          }
          ele.classList.add(`range-${rangeNumber}`)
        }
      }
    },
    clearAllSelectingCell(selector) {
      if (!selector) return
      const allCells = this.findElement(selector)
      // eslint-disable-next-line no-unused-expressions
      allCells.length > 0 && allCells.forEach(cell => {
        cell.classList.remove('in-range')
      })
    },
    selectDateInRange(from, to, selected = false, rangeNumber = 0) {
      const fromDate = from.getDate()
      const fromMonth = from.getMonth() + 1
      const fromYear = from.getFullYear()
      const toDate = to.getDate()
      const toMonth = to.getMonth() + 1
      const toYear = to.getFullYear()
      const selector = 'td.in-range'

      this.clearAllSelectingCell(selector)

      try {
        if (toYear > fromYear) {
          let ranges = []
          for (let i = fromYear; i <= toYear; i += 1) {
            const start = i === fromYear ? fromMonth : 1
            const end = i === toYear ? toMonth : 12
            for (let j = start; j <= end; j += 1) {
              const totalDaysOfMonth = i === toYear && j === toMonth ? toDate : this.daysInMonth(toYear, j)
              const datefrom = i === fromYear && j === fromMonth ? `${fromMonth}/${fromDate}/${fromYear}` : `${j}/1/${i}`
              const dateto = i === toYear && j === toMonth ? `${toMonth}/${toDate}/${toYear}` : `${j}/${totalDaysOfMonth}/${i}`
              ranges = [
                ...ranges,
                [datefrom, dateto],
              ]
            }
          }

          const { length } = ranges
          for (let i = 0; i < length; i += 1) {
            // 1 => right
            // 0 => both
            // -1 => left
            let freePoint = 1
            if (length === 2) {
              freePoint = i === 0 ? -1 : 1
            } else {
              freePoint = 0
              if (i === 0) {
                freePoint = -1
              } else if (i === length - 1) {
                freePoint = 1
              }
            }

            this.highlightDateInRange(...ranges[i], selected, freePoint, rangeNumber)
          }
        } else if (toMonth > fromMonth) {
          const datares = fromMonth - toMonth
          for (let i = fromMonth; i <= toMonth; i += 1) {
            const totalDaysOfMonth = this.daysInMonth(fromYear, i)
            const datefrom = i === fromMonth ? `${fromMonth}/${fromDate}/${fromYear}` : `${i}/${1}/${fromYear}`
            const dateto = i === toMonth && toDate < totalDaysOfMonth ? `${i}/${toDate}/${fromYear}` : `${i}/${totalDaysOfMonth}/${fromYear}`
            let freePoint = 1

            if (datares === 1) {
              freePoint = i === fromMonth ? -1 : 1
            } else {
              freePoint = 0
              if (i === fromMonth) {
                freePoint = -1
              } else if (i === toMonth) {
                freePoint = 1
              }
            }

            this.highlightDateInRange(datefrom, dateto, selected, freePoint, rangeNumber)
          }
        } else {
          const datefrom = `${fromMonth}/${fromDate}/${fromYear}`
          const dateto = `${toMonth}/${toDate}/${toYear}`
          this.highlightDateInRange(datefrom, dateto, selected, undefined, rangeNumber)
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleMouseOver(e, value) {
      if (this.disabled) return
      if (!this.startDate || (this.startDate && this.endDate)) return

      const from = new Date(this.startDate)
      const to = new Date(value)
      const temp = this.tempRange

      temp[1] = value
      this.tempRange = temp
      if (isAfter(to, from) || isEqual(from, to)) {
        this.selectDateInRange(from, to, false, this.results.length + 1)
      } else {
        const selector = 'td.in-range'
        this.clearAllSelectingCell(selector)
      }
    },
    formatValue(value) {
      const v = new Date(value)
      return format(v, this.displayFormat)
    },
    handleClickPrev(numOfMonth) {
      if (this.disabled) return

      const currentMonth = this.curMonth
      const curYear = currentMonth - numOfMonth < 0 ? this.curYear - 1 : this.curYear
      const curMonth = currentMonth - numOfMonth < 0 ? currentMonth - numOfMonth + 12 : currentMonth - numOfMonth
      const nextMonth = curMonth + 1 > 11 ? 0 : curMonth + 1
      const nextYeah = curMonth + 1 > 11 ? curYear + 1 : curYear

      this.curMonth = curMonth
      this.curYear = curYear
      this.leftCalendarDatas = this.renderCalendar(curYear, curMonth)
      this.rightCalendarDatas = this.renderCalendar(nextYeah, nextMonth)

      this.$nextTick(() => {
        this.clearAllHighlighted()
        this.removeHighlightStartdate()
        this.highlightStartdate()
        this.highlightToday()
        this.highlightResults()

        if (this.tempRange.length > 0) {
          const tempFrom = new Date(this.tempRange[0])
          const tempTo = new Date(this.tempRange[1])
          this.selectDateInRange(tempFrom, tempTo, false, this.results.length + 1)
        }
      })
    },
    handleClickNext(numOfMonth) {
      if (this.disabled) return

      const currentMonth = this.curMonth
      const curYear = currentMonth + numOfMonth > 11 ? this.curYear + 1 : this.curYear
      const curMonth = currentMonth + numOfMonth > 11 ? currentMonth + numOfMonth - 12 : currentMonth + numOfMonth
      const nextMonth = curMonth + 1 > 11 ? 0 : curMonth + 1
      const nextYeah = curMonth + 1 > 11 ? curYear + 1 : curYear

      this.curMonth = curMonth
      this.curYear = curYear
      this.leftCalendarDatas = this.renderCalendar(curYear, curMonth)
      this.rightCalendarDatas = this.renderCalendar(nextYeah, nextMonth)

      this.$nextTick(() => {
        this.clearAllHighlighted()
        this.removeHighlightStartdate()
        this.highlightStartdate()
        this.highlightToday()
        this.highlightResults()

        if (this.tempRange.length > 0) {
          const tempFrom = new Date(this.tempRange[0])
          const tempTo = new Date(this.tempRange[1])
          this.selectDateInRange(tempFrom, tempTo, false, this.results.length + 1)
        }
      })
    },
    highlightResults() {
      this.results.forEach((item, i) => {
        const [from, to] = item
        const datefrom = new Date(from)
        const dateto = new Date(to)
        this.selectDateInRange(datefrom, dateto, true, i + 1)
      })
    },
    highlightStartdate() {
      const ele = this.findElement(`td[data-value="${this.startDate}"]`)
      if (ele[0]) {
        ele[0].classList.add('from-date')
      }
    },
    removeHighlightStartdate() {
      const ele = this.findElement('td.from-date')[0]
      if (ele) {
        ele.classList.remove('from-date')
      }
    },
    highlightToday() {
      const today = new Date()
      const todayY = today.getFullYear()
      const todayM = today.getMonth()
      const todayD = today.getDate()
      const ele = this.findElement(`td[data-value="${todayM + 1}/${todayD}/${todayY}"]`)
      if (ele[0]) {
        ele[0].classList.add('today')
      }
    },
    clearAllHighlighted() {
      const ele = this.findElement('td.selected-in-range')
      const eleToday = this.findElement('td.today')[0]
      const eleRange1 = this.findElement('td.range-1')
      const eleRange2 = this.findElement('td.range-2')
      const eleRange3 = this.findElement('td.range-3');

      [...ele, ...eleRange1, ...eleRange2, ...eleRange3].forEach(e => {
        e.classList.remove('selected-from-date', 'selected-to-date', 'selected-in-range', 'range-1', 'range-2', 'range-3')
      })

      if (eleToday) eleToday.classList.remove('today')

      this.removeHighlightStartdate()
    },
    handleClearResult(index) {
      if (this.disabled) return

      const ele = this.findElement(`td.range-${index + 1}`)
      ele.forEach(item => item.classList.remove(`range-${index + 1}`))

      this.results = [
        ...this.results.slice(0, index),
        ...this.results.slice(index + 1),
      ]

      const { length } = this.results.length
      if (length > 0) {
        const to = this.results[length - 1][0]
        if (to) {
          this.jumpTo(to)
        }
      } else {
        this.jumpTo(this.currentLocalDate.dateString, this.currentLocalDate.dateString)
      }
    },
    findElement(selector) {
      return document.querySelectorAll(selector)
    },
    jumpTo(date) {
      const newdate = new Date(date)
      const curMonth = newdate.getMonth()
      const curYear = newdate.getFullYear()
      const nextMonth = curMonth + 1 > 11 ? 0 : curMonth + 1
      const nextYeah = curMonth + 1 > 11 ? curYear + 1 : curYear

      this.curMonth = curMonth
      this.curYear = curYear
      this.leftCalendarDatas = this.renderCalendar(curYear, curMonth)
      this.rightCalendarDatas = this.renderCalendar(nextYeah, nextMonth)

      this.$nextTick(() => {
        this.clearAllHighlighted()
        this.highlightToday()
        this.highlightResults()
      })
    },
    handleRangeSelect(from, to) {
      if (this.disabled || this.results.length === this.limitRanges) return

      this.results = [
        ...this.results,
        [from, to],
      ]
      this.jumpTo(from, to)
    },
    handleRangeChange(value, rangeIndex, valueIndex) {
      this.rangeValidation[rangeIndex] = [true, true]
      const { standarFormat } = value
      const rangeIndexValue = [...this.results[rangeIndex]]
      rangeIndexValue[valueIndex] = standarFormat

      const from = new Date(rangeIndexValue[0])
      const to = new Date(rangeIndexValue[1])

      if (isAfter(from, to)) {
        this.rangeValidation[rangeIndex][valueIndex] = !isAfter(from, to)
        this.$forceUpdate()
        return
      }

      this.results = [
        ...this.results.slice(0, rangeIndex),
        rangeIndexValue,
        ...this.results.slice(rangeIndex + 1),
      ]
    },
  },
}
</script>
