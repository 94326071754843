<template>
  <div ref="mainRef" class="pt-1 pl-1 pr-1">
    <b-row>
      <b-col v-if="enableSearchOnListing" cols="1">
        <HeroInputText
          id="search"
          v-model="value.campaignName"
          label="Search"
          @input="handleSearch($event)"
        />
      </b-col>
      <b-col cols="1">
        <FilterComponent
          :filter-datas="filterDatas"
          @onChange="handleFilterUpdate"
        />
      </b-col>
      <b-col cols="2">
        <SettingComponent
          :columns="header"
          :filtered-columns="filteredColumns"
          @onSettingUpdated="handleSettingUpdated"
        />
      </b-col>
      <b-col cols>
        <ExportExcelComponent
          :header="header"
          :body-data="exportBodyData"
        />
      </b-col>
      <b-col cols="3" class="d-flex">
        <b-col cols="6">
          <HeroCheckbox
            id="enable-highlight"
            v-model="enableHighlight"
            label="Enable Highlight"
            @input="handleEnableHighlight($event)"
          />
        </b-col>
        <b-col cols="6">
          <HeroCheckbox
            id="showActiveOnly"
            v-model="showActiveOnly"
            label="Show Active Campaigns"
            @input="handleShowActiveChange($event)"
          />
        </b-col>
      </b-col>
      <b-col cols="2">
        <b-dropdown id="expandActions" text="[+] Expand" class="w-100">
          <b-dropdown-item @click="handleExpandAll('all', true)">
            All
          </b-dropdown-item>
          <b-dropdown-item
            v-for="item in 2"
            :key="item"
            @click="handleExpandAll(item - 1, true)"
          >
            {{ expandLevel[item - 1] }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="2" class="ml-1">
        <b-dropdown id="collapseActions" text="[+] Collapse" class="w-100">
          <b-dropdown-item @click="handleExpandAll('all', false)">
            All
          </b-dropdown-item>
          <b-dropdown-item
            v-for="item in 2"
            :key="item"
            @click="handleExpandAll(item - 1, false)"
          >
            {{ expandLevel[item - 1] }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <div class="table-wrapper">
      <table id="channel-performance-table" ref="tableRef" style="height: calc(100vh - 250px);">
        <thead v-if="isNoData" class="table-head">
          <tr class="table-head--row">
            <th v-for="item in tableHead" :key="item.key" :class="{
              'th-cell-fixed': item.fixed
            }"
            >
              <span v-if="item.fixed" class="line line-top" />
              <span v-if="item.fixed" class="line line-right" />
              <span v-if="item.fixed" class="line line-bottom" />
              <span v-if="item.fixed" class="line line-left" />
              <div :class="{
                'cell-content d-flex align-center': true,
                'justify-center': item.alignContent === 'center',
                'justify-start': item.alignContent === 'left',
                'justify-end': item.alignContent === 'right',
              }"
              >
                <v-tooltip v-if="item.description" bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.text }}</span>
                  </template>
                  <span v-html="item.description" />
                </v-tooltip>
                <span v-else>{{ item.text }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <template v-if="!isNoData">
            <template v-for="(item, index) in bodyData">
              <GroupedTableContent
                :key="index"
                :heads="tableHead"
                :body-data="item"
                :group-id="index"
                :show-header="index === 'group0'"
                :expand-all="expandAll"
                :enable-highlight="enableHighlight"
                @onSort="handleSort"
              >
                <template v-slot:cell-name="{ data : { rowData, level }}">
                  <template v-if="level === 0">
                    {{ rowData.name }}
                    <a
                      :href="`/campaigns/${rowData.id}`"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="link-to-details"
                      title="Go to details"
                    > [...]</a>
                  </template>
                  <template v-else>
                    {{ rowData.name }}
                  </template>
                </template>
                <template v-slot:cell-actualCampaignStartDate="{ data }">
                  <template v-if="data.cellValue">
                    {{ data.cellValue | moment(dateFormat) }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>

                <template v-slot:cell-actualCampaignEndDate="{ data }">
                  <template v-if="data.cellValue">
                    {{ data.cellValue | moment(dateFormat) }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>

                <template v-slot:cell-amount="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-managementFee="{ data }">
                  {{ data.cellValue | percent }}
                </template>

                <template v-slot:cell-monthlyBudget="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-cpc="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-clientBudget="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-adsBudget="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-spending="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-adsBudgetRemaining="{ data }">
                  <span :class="{
                    'color-red': +data.cellValue < 0,
                  }"
                  >
                    {{ data.cellValue | formatCurrency(data.rowData.currency, false, data.rowData.filterBy) }}
                  </span>
                </template>

                <template v-slot:cell-clientBudgetCPL="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-actualCPL="{ data }">
                  <span :class="{
                    'color-red': +data.cellValue > +data.rowData.adsBudgetCPL,
                  }"
                  >
                    {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                  </span>
                </template>

                <template v-slot:cell-dailyAdsBudget="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>

                <template v-slot:cell-dailySpending="{ data }">
                  <span :class="{
                    'color-red': +data.cellValue > +data.rowData.dailyAdsBudget,
                    'color-blue': +data.cellValue < 0.5 * data.rowData.dailyAdsBudget,
                  }"
                  >
                    {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                  </span>
                </template>

                <template v-slot:cell-dailyAdsBudgetRemaining="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency, false, data.rowData.filterBy) }}
                </template>

                <template v-slot:cell-spendingPercent="{ data }">
                  {{ data.cellValue | percent }}
                </template>

                <template v-slot:cell-kpiPercent="{ data }">
                  <span :class="{
                    'color-orange' : +data.cellValue > 150,
                    'color-green' : +data.cellValue < 150 && +data.cellValue > 100,
                  }"
                  > {{ data.cellValue | percent }}</span>
                </template>

                <template v-slot:cell-percentLead="{ data }">
                  {{ formatMoney(data.cellValue) | percent }}
                </template>

                <template v-slot:cell-estRemainingLeads="{ data }">
                  {{ formatMoney(data.cellValue) }}
                </template>

                <template v-slot:cell-totalEstLeads="{ data }">
                  {{ formatMoney(data.cellValue) }}
                </template>

                <template v-slot:cell-estAchievement="{ data }">
                  {{ formatMoney(data.cellValue) }}
                </template>

                <template v-slot:cell-ctr="{ data }">
                  {{ data.cellValue | fixedNumber | percent }}
                </template>

                <template v-slot:cell-totalDays="{ data }">
                  <template v-if="data.cellValue">
                    {{ data.cellValue }} Days
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>

                <template v-slot:cell-remainingDays="{ data }">
                  <template v-if="data.cellValue">
                    <span :class="{
                      'color-orange' : +data.cellValue < 6,
                    }"
                    >{{ data.cellValue }} Days </span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>

                <template v-slot:cell-conversionRate="{ data }">
                  {{ data.cellValue | percent }}
                </template>
                <template v-slot:cell-dayPassRate="{ data }">
                  {{ data.cellValue | percent }}
                </template>
                <template v-slot:cell-click="{ data }">
                  {{ data.cellValue | formatCurrency('', true) }}
                </template>
                <template v-slot:cell-impression="{ data }">
                  {{ data.cellValue | formatCurrency('', true) }}
                </template>
                <template v-slot:cell-actualKpi="{ data }">
                  {{ data.cellValue | formatCurrency('', true) }}
                </template>
                <template v-slot:cell-clientKpi="{ data }">
                  {{ data.cellValue | formatCurrency('', true) }}
                </template>
                <template v-slot:cell-actualKpiUnitValue="{ data }">
                  {{ data.cellValue.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
                </template>
                <template v-slot:cell-actualCPConv="{ data }">
                  <span :class="{
                    'color-red': +data.cellValue > +data.rowData.adsBudgetCPConv,
                  }"
                  >
                    {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                  </span>
                </template>
                <template v-slot:cell-adsBudgetCPL="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>
                <template v-slot:cell-adsBudgetCPConv="{ data }">
                  {{ data.cellValue | formatCurrency(data.rowData.currency) }}
                </template>
              </GroupedTableContent>
            </template>
          </template>
        </tbody>
      </table>
      <div v-if="isNoData" class="empty-content">
        <div class="cell-content d-flex justify-center">
          No item here. Please select filter first.
        </div>
      </div>
    </div>
    <div>
      <PaginationComponent
        :pagination-data="paginationData"
        class="mt-2"
        @onPageChange="handlePageChange"
        @onPerPageChange="handlePerPageChange"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import {
  fattenData,
  Storage,
  searchMixin,
  groupedData,
  validData,
} from '@/utils/utils'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import DataFormatService from '@/services/DataFormatService'
import SettingComponent from '../channel-components/SettingComponent/SettingComponent.vue'
import FilterComponent from '../channel-components/FilterComponent/FilterComponent.vue'
import PaginationComponent from '../channel-components/PaginationComponent/PaginationComponent.vue'
import GroupedTableContent from '../channel-components/GroupedTableContent/GroupedTableContent.vue'
import ExportExcelComponent from '../channel-components/ExportExcelComponent/ExportExcelComponent.vue'
// import FetchDataComponent from '../channel-components/FetchDataComponent/FetchDataComponent.vue'

export default {
  name: 'TableComponent',
  components: {
    SettingComponent,
    FilterComponent,
    PaginationComponent,
    GroupedTableContent,
    ExportExcelComponent,
    HeroCheckbox,
    // FetchDataComponent,
  },
  filters: {
    formatCurrency(value, currency, isFormatNumber = false, filterBy = 'cycle') {
      if (filterBy !== 'cycle') return '-'
      const currencies = {
        THB: '฿',
        IDR: 'Rp',
        default: '',
      }

      const decimalCount = 2
      const decimal = '.'
      const thousands = ','
      const negativeSign = value < 0 ? '-' : ''
      const i = parseInt(Math.abs(Number(value) || 0).toFixed(decimalCount), 10).toString()
      const j = (i.length > 3) ? i.length % 3 : 0

      /**
     * Calculate decimal number
     * @param a - Real value
     * @param b - Interger value
     * @param c - Decimal Count
     */
      const decimalNumber = (a, b, c) => {
        const firstVal = Math.abs(a)
        const secondVal = Number(b)
        return c ? `${Math.abs(firstVal - secondVal).toFixed(c)}`.slice(2) : ''
      }

      let results = `${negativeSign}${j ? `${i.substr(0, j)}${thousands}` : ''}`
      results = `${results}${i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}`

      if (!isFormatNumber) {
        results = `${results}${decimal}${decimalNumber(value, i, decimalCount)}`
        results = `${currency ? `${currencies[currency]}` : `${currencies.default}`}${results}`
      }

      return results
    },
    fixedNumber(value) {
      if (value === null) return '-'
      return value.toFixed(2)
    },
    percent(value) {
      const val = Number(value)
      if (value === null || Number.isNaN(val)) return '-'
      return `${val}%`
    },
  },
  mixins: [searchMixin],
  props: {
    header: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    responseResult: {
      type: Array,
      default: () => [],
    },
    searchBy: {
      type: Array,
      default: () => [],
    },
    filterDatas: {
      type: Object,
      default: () => {},
    },
    useExternalSearch: {
      type: Boolean,
      default: false,
    },
    paginationData: {
      type: Object,
      default: () => ({
        from: 0,
        to: 0,
        total: 0,
        currPage: 1,
        totalPages: 0,
        perPages: 20,
      }),
    },
    campaigns: {
      type: Array,
      default: () => ([]),
    },
    showFetchData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHead: [],
      filteredColumns: [],
      enableSearchOnListing: false,
      bodyData: {},
      exportBodyData: {},
      isNoData: false,
      dateFormat: 'DD/MM/YYYY',
      expandAll: {
        level: 'all',
        status: false,
      },
      expandLevel: ['All Campaigns', 'All Cycles'],
      tableMetaData: {
        page: 1,
        per_page: 20,
      },
      enableHighlight: false,
      // Options to filter active campaign
      showActiveOnly: false,
      showCurrentContract: false,
      dataInObj: {},
    }
  },
  watch: {
    tableData(newData) {
      this.generateTableData(newData)
    },
    responseResult(newData) {
      this.generateExportTableData(newData)
    },
  },
  created() {
    const filters = Storage.getItem('filter-data')

    this.tableHead = this.header
    this.tableMetaData = {
      ...this.tableMetaData,
      ...filters,
    }
    this.dataInObj = this.header.reduce((res, item) => ({ ...res, [item.key]: item }), {})
    this.filteredColumns = Storage.getItem('filtered-columns') || []
    this.enableHighlight = Storage.getItem('enable-highlight') || false
    this.showCurrentContract = Storage.getItem('show-current-contract') || false
    this.showActiveOnly = Storage.getItem('show-active-campaign') || false
    this.generateTableData(this.tableData)

    this.formatMoney = DataFormatService.formatMoney
  },
  methods: {
    handleSettingUpdated(settings) {
      this.tableHead = this.tableHead.filter(item => item.required)
      this.tableHead = [...this.tableHead, ...settings]
      this.filteredColumns = settings

      Storage.setItem('filtered-columns', this.filteredColumns)
    },
    enhanceData(data) {
      const results = fattenData(data)

      this.isNoData = !(results.length > 0)
      this.bodyData = groupedData(results)
    },
    enhanceExportData(data) {
      const results = fattenData(data)

      this.isNoData = !(results.length > 0)
      this.exportBodyData = groupedData(results)
    },
    handlePageChange(page) {
      Storage.removeItem('rows-shown')
      Storage.removeItem('rows-expanded')

      this.tableMetaData = {
        ...this.tableMetaData,
        page,
      }

      Storage.setItem('filter-data', this.tableMetaData)
      this.$emit('onPageUpdate', this.tableMetaData)
    },
    handlePerPageChange(perPage) {
      Storage.removeItem('rows-shown')
      Storage.removeItem('rows-expanded')

      this.tableMetaData = {
        ...this.tableMetaData,
        page: 1,
        per_page: perPage,
      }

      Storage.setItem('filter-data', this.tableMetaData)
      this.$emit('onFilterUpdate', this.tableMetaData)
    },
    /**
   * Get element Property Value like (padding, margin, ...)
   * @param {Object} - Target element
   * @param {String} - Target property name base on css properties name
   */
    getElementPropertyValue(element, property) {
      if (!element || !property) return 0
      return +window.getComputedStyle(element, null).getPropertyValue(property).replace('px', '')
    },
    generateTableData(data) {
      if (this.tableData.length === 0) {
        this.body = []
      }

      if (this.filteredColumns.length > 0) {
        this.tableHead = this.header.filter(({ required }) => required)
        this.filteredColumns = this.filteredColumns.filter(item => this.dataInObj[item.key])
        this.tableHead = [...this.tableHead, ...this.filteredColumns]
      }

      let deepClone = cloneDeep(data)
      if (this.showActiveOnly) {
        deepClone = deepClone.map(item => {
          const newItem = item
          newItem.childs = item.childs.filter(child => child.statusName === 'Active')
          return newItem
        })
      }

      this.enhanceData(deepClone)
      // this.handleEnableHighlight(this.enableHighlight)
      // this.handleShowActiveChange(this.showActiveOnly)
    },
    generateExportTableData(data) {
      if (this.filteredColumns.length > 0) {
        this.tableHead = this.header.filter(({ required }) => required)
        this.filteredColumns = this.filteredColumns.filter(item => this.dataInObj[item.key])
        this.tableHead = [...this.tableHead, ...this.filteredColumns]
      }

      let deepClone = cloneDeep(data)
      if (this.showActiveOnly) {
        deepClone = deepClone.map(item => {
          const newItem = item
          newItem.childs = item.childs.filter(child => child.statusName === 'Active')
          return newItem
        })
      }

      this.enhanceExportData(deepClone)
    },
    handleFilterUpdate(data) {
      Storage.removeItem('rows-shown')
      Storage.removeItem('rows-expanded')

      this.expandAll = { level: 'all', status: false }
      this.tableMetaData = {
        ...this.tableMetaData,
        ...data,
      }

      Storage.setItem('filter-data', this.tableMetaData)
      this.$emit('onFilterUpdate', this.tableMetaData)
    },
    handleExpandAll(level, status) {
      this.expandAll = { level, status }
    },
    compareDate(a, b, direction) {
      if (direction === 'asc') {
        return this.$moment(a).isAfter(b) ? -1 : 1
      }
      return this.$moment(a).isAfter(b) ? 1 : -1
    },
    handleSort(data) {
      Storage.removeItem('rows-shown')
      Storage.removeItem('rows-expanded')

      const { key, value: direction, datatype } = data
      const results = [...this.tableData].sort((a, b) => {
        const aValue = validData(a[key], datatype)
        const bValue = validData(b[key], datatype)
        if (datatype === 'date') return this.compareDate(aValue, bValue, direction)
        if (datatype === 'string') return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
        return direction === 'asc' ? bValue - aValue : aValue - bValue
      })

      this.enhanceData(results)
    },
    handleEnableHighlight($event) {
      Storage.setItem('enable-highlight', $event)
    },
    handleShowActiveChange($event) {
      this.generateTableData(this.tableData)
      this.expandAll = { level: 'all', status: $event }

      Storage.removeItem('rows-shown')
      Storage.removeItem('rows-expanded')
      Storage.setItem('show-active-campaign', $event)
    },
    handleSearchCampaign(value) {
      this.$emit('onSearchCampaign', value)
    },
    handleOk(data) {
      this.$emit('fetch-data', data)
    },
  },
}
</script>

<style lang="scss">
$border-color: #dedede;
$main-color: #ff3e00;
$white: #fff;

@mixin btn-hover($color) {
    &:hover, &:focus {
        &:before {
            background-color: $color;
        }
    }
}
// End Global Mixins

.table-component {
    display: flex;
    flex-direction: column;
    height: 100%;
    > div {
        display: flex;
        overflow: hidden;
    }

    .btn-expand-all {
        height: initial;
        padding: 0 5px 2px;
        margin: 0;
        text-transform: capitalize;
        font-size: 12px;
        flex: 0 0 auto!important;
        box-shadow: initial!important;
        border: 1px solid #cdcdcd;
        &:focus {
            box-shadow: initial!important;
            outline: initial!important;
        }
        .v-icon {
            font-size: 13px;
            display: block;
            position: relative;
            top: 2px;
        }
    }
}

.table-wrapper {
    position: relative;
    table {
        position: relative;
        display: block;
        overflow: auto;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    thead {
        position: relative;
        th {
            position: sticky;
            top: 0px;
            background: #f3f2f7;
            color: #6e6b7b;
            z-index: 2;
            font-weight: 500;
            &::before {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background: $border-color;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background: $border-color;
            }
        }
    }

    .empty-content {
        position: absolute;
        top: 28px;
        left: 1px;
        bottom: 8px;
        right: 0;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border-color;
        border-bottom: none;
    }

    .toggle-explain {
        height: initial!important;
        min-height: initial!important;
        padding: 0 5px;
        margin: 0;
        text-transform: capitalize;
        font-size: 12px;
    }
}

.hidden {
    display: none;
}

.search-and-setting {
    .table-search {
        flex: 0 0 300px!important;
        .v-label {
            top: 50%;
            transform: translate(0, -50%);
            font-size: 12px;
        }
        .v-input__slot {
            min-height: 0!important;
            padding-right: 5px!important;
            border-width: 1px!important;
            &:hover {
                border-width: 1px!important;
            }
        }
        input {
            max-height: initial!important;
            margin: 0;
            padding: 5px 0;
            font-size: 12px;
        }
        .v-input__append-inner {
            margin-top: 2px!important;
            .v-icon {
                font-size: 18px!important;
                color: #00adff;
            }
        }
    }

    .table-more-actions {
        flex-basis: 190px!important;
        &.left {
            display: flex;
        }
        > div {
            display: inline-block;
            .v-list__tile {
                padding: 0;
            }
        }
        input[type="checkbox"] {
            position: relative;
            top: 2px;
            width: 17px;
            height: 17px;
            line-height: 0;
            font-size: 0;
            float: left;
            &:hover {
                cursor: pointer;
            }
        }
        .btn-fetch-data {
            height: 32px;
            font-size: 13px;
            text-transform: capitalize;
            min-width: initial;
            padding: 0 10px;
            background: #00adff;
            color:$white!important;
            border-radius: 4px;
            opacity: 1;
            line-height: 20px;
            &:focus {
                outline: 2px auto $white;
            }
            @include btn-hover($main-color);
            .v-icon {
                font-size: 18px;
                margin-right: 2px;
            }
        }
    }
}

.expand-menu {
    .v-list__tile__title {
        font-size: 12px!important;
        color: #000!important;
    }
}
</style>
