export function fattenData(data) {
  let results = []
  const totalData = data.length

  for (let i = 0; i < totalData; i += 1) { // Campaign
    const { childs: level1Data, ...restOfLevel0 } = data[i]
    results = [
      ...results,
      {
        masterKey: i,
        level: 0,
        isShow: true,
        ...restOfLevel0,
      },
    ]
    if (level1Data) { // Cycle
      const totalLevel1 = level1Data.length
      for (let j = 0; j < totalLevel1; j += 1) {
        const { childs: level2Data, ...restOfLevel1 } = level1Data[j]
        results = [
          ...results,
          {
            level: 1,
            masterKey: i,
            level1Key: j,
            isShow: false,
            isLastOfLevel1: j === totalLevel1 - 1,
            ...restOfLevel1,
          },
        ]
        if (level2Data) {
          const totalLevel2 = level2Data.length
          for (let k = 0; k < totalLevel2; k += 1) { // Product Family
            const { childs: level3Data, ...restOfLevel2 } = level2Data[k]
            // Product
            const level3DataNew = level3Data ? level3Data.map((item, index) => ({
              level: 3,
              masterKey: i,
              level1Key: j,
              level2Key: k,
              level3Key: index,
              isShow: false,
              isBelongToLastOfLevel2: j === totalLevel1 - 1 && k === totalLevel2 - 1,
              ...item,
            })) : []

            level3DataNew[level3DataNew.length - 1] = {
              ...level3DataNew[level3DataNew.length - 1],
              level3LastItem: true,
            }

            results = [
              ...results,
              {
                level: 2,
                masterKey: i,
                level1Key: j,
                level2Key: k,
                isShow: false,
                isLastOfLevel2: j === totalLevel1 - 1 && k === totalLevel2 - 1,
                ...restOfLevel2,
              },
              ...level3DataNew,
            ]
          }
        }
      }
    }
  }
  return results
}

export const groupedData = data => {
  const result = data.reduce((groups, item) => {
    const { masterKey } = item
    const groupsdata = groups
    if (!groupsdata[`group${masterKey}`]) groupsdata[`group${masterKey}`] = []

    groupsdata[`group${masterKey}`] = [
      ...groupsdata[`group${masterKey}`],
      item,
    ]
    return groupsdata
  }, {})
  return result
}

class StorageService {
  constructor() {
    this.storage = window.localStorage
    // this.baseKey = "ng2-webstorage|table|";
    this.baseKey = 'table|'
  }

  setItem(name, value) {
    this.storage.setItem(`${this.baseKey}${name}`, JSON.stringify(value))
    return this
  }

  getItem(name) {
    return JSON.parse(this.storage.getItem(`${this.baseKey}${name}`))
  }

  removeItem(name) {
    this.storage.removeItem(`${this.baseKey}${name}`)
    return this
  }

  clearAll() {
    this.storage.clear()
    return this
  }
}

export const Storage = new StorageService()

export const findItems = (data, searchKeys, value) => {
  const itemvalue = value.toLowerCase()
  let results = []

  if (data.length === 0) return []

  data.forEach((item, index) => {
    const { childs, ...res } = item
    let match = false
    searchKeys.forEach(key => {
      const itemVal = `${item[key]}`.toLowerCase()
      if (itemVal.includes(itemvalue)) {
        results[index] = { ...res, childs }
        match = true
      }
    })

    if (childs) {
      const childResults = findItems(childs, searchKeys, value)

      if (childResults.length > 0) {
        results[index] = {
          ...res,
          childs: childResults,
        }
      } else if (match) {
        results[index] = {
          ...results[index],
        }
      }
    }
  })
  results = results.filter(item => !!item)
  return results
}

/**
   * Mixin for search
   */
export const searchMixin = {
  data() {
    return ({
      isCachedSetting: false,
      cacheTableSettings: {},
      timer: null,
      isSearching: false,
    })
  },
  methods: {
    firstItemWithAllChilds(data) {
      let isFound = false
      let results = []

      for (let i = 0; i < data.length; i += 1) {
        if (isFound && data[i].level === 0) break

        if (data[i].level === 0) isFound = true
        results = [...results, data[i]]
      }

      return results
    },
    handleSearch(val) {
      if (this.useExternalSearch) {
        this.$emit('onSearch', val)
        return
      }

      /**
         * Default search functionality
         */
      if (!val) {
        // this.timer && clearTimeout(this.timer)
        Storage.setItem('rows-shown', this.cacheTableSettings.rowsShown || [])
        Storage.setItem('rows-explained', this.cacheTableSettings.rowsExplained || [])
        this.enhanceData()
        return
      }
      const newVal = val.toLowerCase()

      if (!this.isCachedSetting) {
        this.cacheTableSettings.rowsShown = Storage.getItem('rows-shown')
        this.cacheTableSettings.rowsExplained = Storage.getItem('rows-explained')
        Storage.setItem('rows-shown', [])
        Storage.setItem('rows-explained', [])
      }

      this.isSearching = true
      // this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let shownRows = []
        let explainRows = []
        const results = findItems(this.tableData, this.searchBy, newVal)
        const Fattendata = fattenData(results)
        // Find the first item and childs to show
        const firstItem = this.firstItemWithAllChilds(Fattendata).map(item => ({ ...item, isShow: true }))

        this.body = [
          ...firstItem,
          ...Fattendata.slice(firstItem.length),
        ]

        firstItem.forEach((item, index) => {
          switch (item.level) {
            case 0:
              explainRows = [...explainRows, index]
              break
            case 1:
            case 2:
              explainRows = [...explainRows, index]
              shownRows = [...shownRows, index]
              break
            default:
              shownRows = [...shownRows, index]
              break
          }
        })

        this.explainRows = explainRows
        Storage.setItem('rows-shown', shownRows)
        Storage.setItem('rows-explained', explainRows)
      }, 500)
    },
  },
}

export const validData = (value, datatype) => {
  switch (datatype) {
    case 'date':
      return !value ? null : value
    case 'string':
      return !value ? '' : value
    default:
      return !value ? 0 : parseFloat(value)
  }
}

/**
   * Parse the time to string
   * @param {(Object|string|number)} time
   * @param {string} cFormat
   * @returns {string | null}
   */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  let newtime = time
  if (typeof time === 'object') {
    date = newtime
  } else {
    if ((typeof newtime === 'string')) {
      if ((/^[0-9]+$/.test(newtime))) {
        // support "1548221490638"
        newtime = parseInt(newtime, 10)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        newtime = newtime.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof newtime === 'number') && (newtime.toString().length === 10)) {
      newtime *= 1000
    }
    date = new Date(newtime)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const TimeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    // if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (key === 'a') { return ['Day', 'one', 'two', 'three', 'four', 'five', 'six'][value] }
    return value.toString().padStart(2, '0')
  })
  return TimeStr
}

export const defaultFilter = {
  campaign: {
    campaignName: '',
    kpi: [],
    objective: [],
  },
  country: {
    country: [],
  },
  date: {
    compareBy: 'date',
    datePicker: [],
    endableCompare: true,
    period: 1,
  },
  product: {
    productFamilys: [],
    products: [],
  },
  staff: {
    ams: [],
    optimizers: [],
  },
  status: {
    status: [],
  },
}

export const defaultPerPage = '20'
export const perPagesRange = [{ text: '20', value: '20' }, { text: '50', value: '50' }, { text: '100', value: '100' }, { text: '200', value: '200' }]
